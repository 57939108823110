<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal" aria-label="Close modal">×</button>

      <div class="modal-body">
        <!-- Image Section -->
        <div class="painting-full">
          <div 
            class="image-container"
            @click="triggerFileInput"
          >
            <img 
              v-if="currentMainImage" 
              :src="currentMainImage" 
              :alt="editableTitle || 'Painting'"
            />
            <div v-else class="image-placeholder">
              <span>Click to Upload Image</span>
            </div>
            <div v-if="editableSold" class="sold-overlay">SOLD</div>
          </div>
          <input 
            type="file" 
            ref="fileInput" 
            style="display: none;" 
            @change="onFileChange"
            accept="image/*"
            multiple
          />
          <!-- Thumbnails Section -->
          <div class="thumbnails-container">
            <div 
              v-for="(img, index) in editableImageUrls" 
              :key="index" 
              class="thumbnail-wrapper"
            >
              <img 
                :src="img.preview" 
                :alt="`Thumbnail ${index + 1}`"
                @click="setMainImage(index)"
                :class="{ 'active': index === currentMainImageIndex }"
              />
              <button 
                v-if="!isViewMode"
                class="delete-thumbnail-btn" 
                @click.stop="deleteThumbnail(index)" 
                aria-label="Delete thumbnail"
              >
                ×
              </button>
            </div>
            <!-- Add dummy thumbnail for uploading new image -->
            <div
              v-if="!isViewMode" 
              class="dummy-thumbnail"
              @click="triggerFileInput"
            >
              <span>+</span>
            </div>
          </div>
        </div>

        <!-- Form Section -->
        <div class="painting-info">
          <h2 class="painting-title">
            {{ isViewMode ? 'Painting Details' : (painting ? 'Edit Painting' : 'Add New Painting') }}
          </h2>
          <form @submit.prevent="savePainting" v-if="!isViewMode">
            <div class="form-group">
              <label for="title">Title:</label>
              <input id="title" v-model="editableTitle" required />
            </div>

            <div class="form-group">
              <label for="artistName">Artist:</label>
              <input id="artistName" v-model="editableArtistName" required />
            </div>

            <div class="form-group">
              <label for="year">Year:</label>
              <input id="year" v-model="editableYear" type="number" required />
            </div>

            <div class="form-group">
              <label for="dimensions">Dimensions:</label>
              <input id="dimensions" v-model="editableDimensions" required />
            </div>

            <div class="form-group">
              <label for="medium">Medium:</label>
              <input id="medium" v-model="editableMedium" required />
            </div>

            <div class="form-group">
              <label for="style">Style:</label>
              <input id="style" v-model="editableStyle" required />
            </div>

            <div class="form-group">
              <label for="subject">Subject:</label>
              <textarea id="subject" v-model="editableSubject" required></textarea>
            </div>

            <div class="form-group">
              <label for="price">Price:</label>
              <input id="price" v-model="editablePrice" required />
            </div>

            <div class="form-group">
              <label for="provenance">Description:</label>
              <textarea id="provenance" v-model="editableProvenance"></textarea>
            </div>

            <div class="form-group">
              <label for="conditionReport">Condition Report:</label>
              <textarea id="conditionReport" v-model="editableConditionReport"></textarea>
            </div>

            <div class="form-group">
              <label for="certificateOfAuthenticity">Certificate of Authenticity:</label>
              <input id="certificateOfAuthenticity" type="checkbox" v-model="editableCertificateOfAuthenticity" />
            </div>

            <div class="form-group">
              <label for="sold">Sold:</label>
              <input id="sold" type="checkbox" v-model="editableSold" />
            </div>

            <div class="button-group">
              <button type="submit" class="save-btn">
                {{ painting ? 'Save Changes' : 'Add Painting' }}
              </button>
              <button v-if="painting" @click.prevent="deletePainting" class="delete-btn">
                Delete Painting
              </button>
            </div>
          </form>
          <div v-else>
            <!-- Display read-only painting information here -->
            <p><strong>Title:</strong> {{ editableTitle }}</p>
            <p><strong>Artist:</strong> {{ editableArtistName }}</p>
            <p><strong>Year:</strong> {{ editableYear }}</p>
            <p><strong>Dimensions:</strong> {{ editableDimensions }}</p>
            <p><strong>Medium:</strong> {{ editableMedium }}</p>
            <p><strong>Style:</strong> {{ editableStyle }}</p>
            <p><strong>Subject:</strong> {{ editableSubject }}</p>
            <p><strong>Price:</strong> {{ editablePrice }}</p>
            <p><strong>Description:</strong> {{ editableProvenance }}</p>
            <p><strong>Condition Report:</strong> {{ editableConditionReport }}</p>
            <p><strong>Certificate of Authenticity:</strong> {{ editableCertificateOfAuthenticity ? 'Yes' : 'No' }}</p>
            <p><strong>Sold:</strong> {{ editableSold ? 'Yes' : 'No' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storage, db } from '../firebase';
import { ref as storageRef, deleteObject } from 'firebase/storage';
import { deleteDoc, doc } from 'firebase/firestore';

export default {
  name: 'PaintingModal',
  props: ['painting', 'user'],
  data() {
    return {
      editableTitle: '',
      editableArtistName: '',
      editableYear: '',
      editableDimensions: '',
      editableMedium: '',
      editableStyle: '',
      editableSubject: '',
      editablePrice: '',
      editableProvenance: '',
      editableConditionReport: '',
      editableCertificateOfAuthenticity: false,
      editableImageUrls: [],
      currentMainImageIndex: 0,
      editableSold: false,
    };
  },
  computed: {
    currentMainImage() {
      return this.editableImageUrls[this.currentMainImageIndex]?.preview || '';
    },
    isViewMode() {
      return !this.user;
    }
  },
  watch: {
    painting: {
      immediate: true,
      handler(newPainting) {
        if (newPainting) {
          this.initializeFormWithPaintingData();
        } else {
          this.resetForm();
        }
      }
    }
  },
  methods: {
    initializeFormWithPaintingData() {
      const fields = [
        'title', 'artistName', 'year', 'dimensions', 'medium', 'style', 
        'subject', 'price', 'provenance', 'conditionReport', 'certificateOfAuthenticity'
      ];
      fields.forEach(field => {
        this[`editable${field.charAt(0).toUpperCase() + field.slice(1)}`] = this.painting[field];
      });
      this.editableImageUrls = this.painting.imageUrls.map(url => ({ preview: url }));
      this.editableSold = this.painting.sold || false;
    },
    resetForm() {
      const fields = [
        'title', 'artistName', 'year', 'dimensions', 'medium', 'style', 
        'subject', 'price', 'provenance', 'conditionReport', 'certificateOfAuthenticity'
      ];
      fields.forEach(field => {
        this[`editable${field.charAt(0).toUpperCase() + field.slice(1)}`] = '';
      });
      this.editableCertificateOfAuthenticity = false;
      this.editableImageUrls = [];
      this.editableSold = false;
    },
    closeModal() {
      this.$emit('close');
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async onFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const filePreview = URL.createObjectURL(file);
          this.editableImageUrls.push({
            preview: filePreview,
            file: file
          });
        }
        this.currentMainImageIndex = this.editableImageUrls.length - 1;
      }
    },
    async savePainting() {
      const paintingData = {
        title: this.editableTitle,
        artistName: this.editableArtistName,
        year: this.editableYear,
        dimensions: this.editableDimensions,
        medium: this.editableMedium,
        style: this.editableStyle,
        subject: this.editableSubject,
        price: this.editablePrice,
        provenance: this.editableProvenance,
        conditionReport: this.editableConditionReport,
        certificateOfAuthenticity: this.editableCertificateOfAuthenticity,
        imageUrls: this.editableImageUrls.map(img => img.preview || img),
        sold: this.editableSold,
      };

      if (this.painting) {
        paintingData.id = this.painting.id;
      }

      // Pass the actual File objects along with the painting data
      const fileObjects = this.editableImageUrls
        .filter(img => img.file instanceof File)
        .map(img => img.file);

      if (this.painting) {
        this.$emit('save', { painting: paintingData, fileObjects });
      } else {
        this.$emit('add', { painting: paintingData, fileObjects });
      }

      this.closeModal();
    },
    deletePainting() {
      if (confirm('Are you sure you want to delete this painting?')) {
        const paintingRef = doc(db, 'images', this.painting.id);
        deleteDoc(paintingRef)
          .then(() => {
            const imageRef = storageRef(storage, `images/${this.painting.fileName}`);
            deleteObject(imageRef)
              .then(() => {
                this.$emit('delete', this.painting.id);
                this.closeModal();
              })
              .catch((error) => {
                console.error('Error deleting image file:', error);
              });
          })
          .catch((error) => {
            console.error('Error deleting painting metadata:', error);
          });
      }
    },
    setMainImage(index) {
      this.currentMainImageIndex = index;
    },
    deleteThumbnail(index) {
      if (confirm('Are you sure you want to delete this image?')) {
        this.editableImageUrls.splice(index, 1);
        if (this.currentMainImageIndex >= this.editableImageUrls.length) {
          this.currentMainImageIndex = this.editableImageUrls.length - 1;
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #333;
  z-index: 10;
}

.modal-body {
  display: flex;
  flex-direction: row;
  height: calc(90vh - 30px);
  overflow: hidden;
}

.painting-full {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 50%;
}

.image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnails-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.thumbnail-wrapper {
  position: relative;
  width: 60px;
  height: 60px;
}

.thumbnails-container img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.thumbnails-container img:hover,
.thumbnails-container img.active {
  border-color: #4CAF50;
}

.delete-thumbnail-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f44336;
  color: white;
  border: none;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.delete-thumbnail-btn:hover {
  background-color: #d32f2f;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 1.2em;
  text-align: center;
}

.painting-info {
  flex: 1;
  padding: 30px;
  overflow-y: auto;
  max-width: 50%;
}

.painting-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input {
  margin-right: 10px;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.save-btn,
.delete-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
  margin-left: 10px;
}

.save-btn:hover,
.delete-btn:hover {
  transform: translateY(-2px);
}

.save-btn:hover {
  background-color: #45a049;
}

.delete-btn:hover {
  background-color: #da190b;
}

.dummy-thumbnail {
  width: 60px;
  height: 60px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  color: #ccc;
  transition: all 0.3s ease;
}

.dummy-thumbnail:hover {
  border-color: #999;
  color: #999;
}

.sold-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  background-color: rgb(255, 0, 0, 0.8);
  color: white;
  font-size: 0.9em;
  font-weight: bold;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .modal-body {
    flex-direction: column;
  }

  .painting-full,
  .painting-info {
    max-width: 100%;
  }

  .painting-full {
    height: 50vh;
  }

  .painting-info {
    height: calc(40vh - 30px);
  }
}
</style>