import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../components/LandingPage.vue'
import ImageGallery from '../components/ImageGallery.vue'
import BlogSection from '../components/BlogSection.vue'
import ContactPage from '../components/ContactPage.vue'
import MessageList from '../components/MessageList.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: ImageGallery
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogSection
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/messages',
    name: 'Messages',
    component: MessageList
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
