import { createStore } from 'vuex';
import { db, storage, auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const store = createStore({
  state: {
    paintings: [], // Array to store paintings
    blogPosts: [], // Add this line
    user: null,    // To store user info
    messages: [], // Add this line to store messages
  },
  mutations: {
    setPaintings(state, paintings) {
      state.paintings = paintings;
    },
    addPainting(state, painting) {
      state.paintings.push(painting);
    },
    deletePainting(state, paintingId) {
      state.paintings = state.paintings.filter(painting => painting.id !== paintingId);
    },
    setUser(state, user) {
      state.user = user;
    },
    updatePainting(state, updatedPainting) {
      const index = state.paintings.findIndex(painting => painting.id === updatedPainting.id);
      if (index !== -1) {
        state.paintings[index] = updatedPainting;
      }
    },
    setBlogPosts(state, posts) {
      state.blogPosts = posts;
    },
    addBlogPost(state, post) {
      state.blogPosts.unshift(post);
    },
    updateBlogPost(state, updatedPost) {
      const index = state.blogPosts.findIndex(post => post.id === updatedPost.id);
      if (index !== -1) {
        state.blogPosts[index] = updatedPost;
      }
    },
    deleteBlogPost(state, postId) {
      state.blogPosts = state.blogPosts.filter(post => post.id !== postId);
    },
    addMessage(state, message) {
      state.messages.push(message);
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    updateMessageReadStatus(state, messageId) {
      const message = state.messages.find(m => m.id === messageId);
      if (message) {
        message.read = true;
      }
    },
  },
  actions: {
    async login({ commit }, credentials) {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
        commit('setUser', userCredential.user);
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
    },
    async logout({ commit }) {
      try {
          await signOut(auth);
          commit('setUser', null);
      } catch (error) {
          console.error('Error logging out:', error);
      }
    },
    async fetchPaintings({ commit }) {
      try {
        const querySnapshot = await getDocs(collection(db, 'images'));
        const paintings = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        paintings.sort((a, b) => {
          if (b.year === a.year) {
            return b.timestamp - a.timestamp; // Sort by timestamp if years are equal
          }
          return b.year - a.year; // Sort by year
        });

        commit('setPaintings', paintings);
      } catch (error) {
        console.error('Error fetching paintings:', error);
      }
    },
    async addPainting({ commit }, { painting, fileObjects }) {
      try {
        const newImageUrls = [];
        for (let i = 0; i < painting.imageUrls.length; i++) {
          const imageUrl = painting.imageUrls[i];
          if (imageUrl.startsWith('blob:')) {
            const file = fileObjects[i];
            const uniqueFileName = `${uuidv4()}_${file.name}`; // Use UUID here
            const storageReference = storageRef(storage, `images/${uniqueFileName}`);
            await uploadBytes(storageReference, file);
            const url = await getDownloadURL(storageReference);
            newImageUrls.push(url);
          } else {
            newImageUrls.push(imageUrl);
          }
        }

        const paintingData = {
          ...painting,
          imageUrls: newImageUrls,
          timestamp: serverTimestamp(),
          sold: painting.sold || false,
        };

        const docRef = await addDoc(collection(db, 'images'), paintingData);
        const newPainting = { id: docRef.id, ...paintingData };
        commit('addPainting', newPainting);
      } catch (error) {
        console.error('Error adding painting:', error);
      }
    },
    async deletePainting({ commit }, paintingId) {
      try {
        await deleteDoc(doc(db, 'images', paintingId));
        const imageRef = storageRef(storage, `images/${paintingId}`); // Adjust as needed
        await deleteObject(imageRef);

        commit('deletePainting', paintingId);
      } catch (error) {
        console.error('Error deleting painting:', error);
      }
    },
    async updatePainting({ commit }, { painting, fileObjects }) {
      try {
        const newImageUrls = [];
        for (let i = 0; i < painting.imageUrls.length; i++) {
          const imageUrl = painting.imageUrls[i];
          if (imageUrl.startsWith('blob:')) {
            const file = fileObjects[i];
            if (file && file.name) {
              const uniqueFileName = `${uuidv4()}_${file.name}`; // Use UUID here
              const storageReference = storageRef(storage, `images/${painting.id}/${uniqueFileName}`);
              await uploadBytes(storageReference, file);
              const url = await getDownloadURL(storageReference);
              newImageUrls.push(url);
            } else {
              console.error('Invalid file object:', file);
              newImageUrls.push(imageUrl); // Keep the blob URL if file is invalid
            }
          } else {
            newImageUrls.push(imageUrl);
          }
        }

        const updatedPaintingData = {
          ...painting,
          imageUrls: newImageUrls,
          sold: painting.sold || false,
        };

        const paintingRef = doc(db, 'images', painting.id);
        await updateDoc(paintingRef, updatedPaintingData);

        commit('updatePainting', updatedPaintingData);
      } catch (error) {
        console.error('Error updating painting:', error);
      }
    },
    async fetchBlogPosts({ commit }) {
      try {
        const querySnapshot = await getDocs(collection(db, 'blogPosts'));
        const posts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        posts.sort((a, b) => b.date - a.date); // Sort by date in descending order
        commit('setBlogPosts', posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    },
    async addBlogPost({ commit, state }, post) {
      try {
        const postData = {
          ...post,
          author: post.author || state.user.displayName || state.user.email,
          timestamp: serverTimestamp(),
        };
        const docRef = await addDoc(collection(db, 'blogPosts'), postData);
        const newPost = { id: docRef.id, ...postData };
        commit('addBlogPost', newPost);
        return newPost;
      } catch (error) {
        console.error('Error adding blog post:', error);
        throw error;
      }
    },
    async updateBlogPost({ commit }, post) {
      try {
        const postRef = doc(db, 'blogPosts', post.id);
        await updateDoc(postRef, post);
        commit('updateBlogPost', post);
        return post;
      } catch (error) {
        console.error('Error updating blog post:', error);
        throw error;
      }
    },
    async deleteBlogPost({ commit }, postId) {
      try {
        await deleteDoc(doc(db, 'blogPosts', postId));
        commit('deleteBlogPost', postId);
      } catch (error) {
        console.error('Error deleting blog post:', error);
        throw error;
      }
    },
    async uploadBlogImage(_, file) {
      try {
        const uniqueFileName = `${uuidv4()}_${file.name}`;
        const storageReference = storageRef(storage, `blog-images/${uniqueFileName}`);
        await uploadBytes(storageReference, file);
        const url = await getDownloadURL(storageReference);
        return url;
      } catch (error) {
        console.error('Error uploading blog image:', error);
        throw error;
      }
    },
    async sendMessage({ commit }, messageData) {
      try {
        const messageWithTimestamp = {
          ...messageData,
          timestamp: serverTimestamp(),
          read: false
        };
        const docRef = await addDoc(collection(db, 'messages'), messageWithTimestamp);
        const newMessage = { id: docRef.id, ...messageWithTimestamp };
        commit('addMessage', newMessage);
        return newMessage;
      } catch (error) {
        console.error('Error sending message:', error);
        throw error;
      }
    },
    async fetchMessages({ commit }) {
      try {
        const querySnapshot = await getDocs(collection(db, 'messages'));
        const messages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        commit('setMessages', messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    async markMessageAsRead({ commit }, messageId) {
      try {
        const messageRef = doc(db, 'messages', messageId);
        await updateDoc(messageRef, { read: true });
        commit('updateMessageReadStatus', messageId);
      } catch (error) {
        console.error('Error marking message as read:', error);
      }
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
  },
  getters: {
    paintings: (state) => state.paintings,
    user: (state) => state.user,
    blogPosts: (state) => state.blogPosts,
    messages: (state) => state.messages,
  },
});

export default store;