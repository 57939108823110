<template>
  <div class="gallery-container">
    <FilterSortControls
      :paintings="paintings"
      @filter="handleFilter"
      @sort="handleSort"
    />
    <div v-if="isLoading" class="loading">Loading paintings...</div>
    <div v-else-if="displayedPaintings.length === 0 && !user" class="no-paintings">No paintings found.</div>
    <div v-else class="gallery">
      <!-- Add Image Card -->
      <div v-if="user" class="painting add-image-card" @click="openAddModal">
        <div class="add-image-content">
          <span class="add-image-icon">+</span>
          <div class="painting-title">Add Painting</div>
          <div class="painting-description">Click to add a new artwork</div>
        </div>
      </div>

      <!-- Gallery Images -->
      <div
        class="painting"
        v-for="image in displayedPaintings"
        :key="image.id"
        @click="selectPainting(image)"
      >
        <div class="image-container">
          <img :src="image.imageUrls && image.imageUrls[0]" :alt="image.title" />
          <div v-if="image.sold" class="sold-overlay">SOLD</div>
        </div>
        <div class="painting-info">
          <div class="painting-title">{{ image.title + ' ' + '( ' + (!image.sold ? image.price : 'Sold') + ' )' }}</div>
          <div class="painting-details">
            <div class="painting-column">
              <div class="painting-artist">{{ image.artistName }}</div>
              <div class="painting-year">{{ image.year }}</div>
            </div>
            <div class="painting-column">
              <div class="painting-medium">{{ image.medium }}</div>
              <div class="painting-dimensions">{{ image.dimensions }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Painting Modal -->
    <PaintingModal
      v-if="showModal"
      :painting="selectedPainting"
      :user="user"
      @close="closeModal"
      @save="updatePainting"
      @add="addPainting"
      @delete="deletePainting"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import PaintingModal from './PaintingModal.vue';
import FilterSortControls from './FilterSortControls.vue';

export default {
  name: 'ImageGallery',
  components: {
    PaintingModal,
    FilterSortControls,
  },
  setup() {
    const store = useStore();
    const selectedPainting = ref(null);
    const showModal = ref(false);
    const displayedPaintings = ref([]);
    const isLoading = ref(true);

    const user = computed(() => store.getters.user);
    const paintings = computed(() => store.getters.paintings);

    onMounted(async () => {
      await store.dispatch('fetchPaintings');
      isLoading.value = false;
    });

    watch(paintings, (newPaintings) => {
      displayedPaintings.value = newPaintings;
    }, { immediate: true });

    function selectPainting(painting) {
      selectedPainting.value = painting;
      showModal.value = true;
    }

    function openAddModal() {
      selectedPainting.value = null;
      showModal.value = true;
    }

    function closeModal() {
      showModal.value = false;
      selectedPainting.value = null;
    }

    function updatePainting(updatedData) {
      store.dispatch('updatePainting', updatedData);
    }

    function deletePainting(paintingId) {
      store.dispatch('deletePainting', paintingId);
    }

    function addPainting(newPainting) {
      store.dispatch('addPainting', newPainting);
    }

    function handleFilter(filteredPaintings) {
      displayedPaintings.value = filteredPaintings;
    }

    function handleSort(sortedPaintings) {
      displayedPaintings.value = sortedPaintings;
    }

    return {
      user,
      paintings,
      displayedPaintings,
      selectedPainting,
      showModal,
      isLoading,
      selectPainting,
      openAddModal,
      closeModal,
      updatePainting,
      deletePainting,
      addPainting,
      handleFilter,
      handleSort,
    };
  },
};
</script>

<style scoped>
.gallery-container {
  margin: 0 auto;
  padding: 20px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.painting {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.painting:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.image-container {
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
}

.painting img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.painting:hover img {
  transform: scale(1.05);
}

.add-image-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border: 2px dashed #ccc;
  transition: all 0.3s ease;
}

.add-image-card:hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.add-image-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.add-image-icon {
  font-size: 3em;
  margin-bottom: 10px;
  color: #666;
}

.painting-info {
  padding: 15px;
  text-align: left;
}

.painting-title {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #333;
}

.painting-details {
  display: flex;
  margin-bottom: 5px;
}

.painting-column {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.painting-artist,
.painting-medium {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 3px;
  text-align: left;
  font-weight: bold;
}

.painting-year,
.painting-dimensions {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 3px;
  text-align: left;
}

.sold-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  background-color: rgb(255, 0, 0, 0.8);
  color: white;
  font-size: 0.9em;
  font-weight: bold;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }

  .gallery-title {
    font-size: 2em;
  }
}

.loading, .no-paintings {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}
</style>