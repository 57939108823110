<template>
  <div class="filter-sort-controls">
    <div class="filter-controls">
      <input v-model="searchTerm" @input="applyFilters" placeholder="Search all fields..." />
    </div>
    <div class="sort-controls">
      <select v-model="sortField" @change="applySort">
        <option value="">Sort by...</option>
        <option value="year">Year</option>
        <option value="price">Price</option>
      </select>
      <button @click="toggleSortOrder">
        {{ sortOrder === 'asc' ? '▲' : '▼' }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'FilterSortControls',
  props: {
    paintings: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const searchTerm = ref('');
    const sortField = ref('');
    const sortOrder = ref('asc');

    const applyFilters = () => {
      const filtered = props.paintings.filter(painting => {
        if (!searchTerm.value) return true;
        return Object.values(painting).some(value => 
          String(value).toLowerCase().includes(searchTerm.value.toLowerCase())
        );
      });
      emit('filter', filtered);
    };

    const applySort = () => {
      if (!sortField.value) return;
      const sorted = [...props.paintings].sort((a, b) => {
        let valueA, valueB;
        
        if (sortField.value === 'price') {
          valueA = a.sold ? 0 : parseFloat(a.price.replace(/[^0-9.-]+/g, ''));
          valueB = b.sold ? 0 : parseFloat(b.price.replace(/[^0-9.-]+/g, ''));
        } else if (sortField.value === 'year') {
          valueA = parseInt(a.year);
          valueB = parseInt(b.year);
        }
        
        if (valueA < valueB) return sortOrder.value === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortOrder.value === 'asc' ? 1 : -1;
        return 0;
      });
      emit('sort', sorted);
    };

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      applySort();
    };

    watch(searchTerm, applyFilters);
    watch([sortField, sortOrder], applySort);

    return {
      searchTerm,
      sortField,
      sortOrder,
      applyFilters,
      applySort,
      toggleSortOrder
    };
  }
};
</script>

<style scoped>
.filter-sort-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-controls, .sort-controls {
  display: flex;
  gap: 10px;
}

.filter-controls {
  flex-grow: 1;
  margin-right: 20px;
}

input, select, button {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
  background-color: #f0f0f0;
}

button:hover {
  background-color: #e0e0e0;
}
</style>